import React, { Component } from 'react'
import { JsonToTable } from "react-json-to-table";
import { connect } from "react-redux"

import store from '../../store'
import { stringify_values } from '../../utils/json.utils.js';
import { getCookie, loginUser, getUserInfo, logout, mapAuthStateToObject } from '../../services/auth.service.js'
import { setAuthState } from "../../store/auth/actions"
import { withRouter } from '../../utils/navigation.utils'
import Button from '../Button'

class Login extends Component {

    componentDidMount() {
        getUserInfo().then(data => {
            console.log("userInfo", data)
            store.dispatch(setAuthState(data));
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const user_info = {
            username: event.target.username.value,
            password: event.target.password.value,
        }
        console.log("Logging in as: ", JSON.stringify(user_info))

        const response = loginUser(user_info)

        response.then(resp => {
            return resp.json()
        })
            .then(data => {
                console.log("JWT generated:")
                console.log(data)

                window.location.reload()

                const token = getCookie('csrf_access_token')
                console.log(token)
            })
            .catch(error => {
                console.error("There was an error authenticating the user", error)
            })
    }

    logout() {
        logout().then(() => { window.location.reload() })
    }

    render() {

        return (
            <div className="row" >
                <div className="col-md-6 mt-3 mx-auto">
                    {this.props.authenticated ?
                        <div>
                            <div className="mb-2 row">
                                <JsonToTable json={stringify_values({
                                    Nombre: this.props.user.name,
                                    Usuario: this.props.user.username,
                                    Acceso: (this.props.user.access === 0 ? "Admin" : (this.props.user.access === 1 ? "Mentor" : "Student")),

                                })} />
                                {/* {"You are logged in as: " + JSON.stringify(this.props.user)} */}
                            </div>
                            <div className="row col-md-4 mx-auto">
                                <button className="btn btn-primary btn-block"
                                    onClick={(e) => this.logout()}>
                                    Logout
                                </button>
                            </div>
                        </div>
                        :
                        <div className="row form-group mb-3">
                            <form onSubmit={this.handleSubmit} className="card card-body">
                                <div className="form-group mb-2">
                                    <input type="username"
                                        autoFocus
                                        className="form-control"
                                        placeholder="Usuario"
                                        name="username"
                                    />
                                </div>
                                <div className="form-group mb-2">
                                    <input type="password"
                                        className="form-control"
                                        placeholder="Contraseña"
                                        name="password"
                                        maxLength="70"
                                    />
                                </div>
                                <Button
                                    text="Autenticar"
                                />

                            </form>
                        </div>}
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapAuthStateToObject, { setAuthState })(Login))
