import React, { Component } from 'react'
import { getStatus } from '../../services/system.service.js'

export default class HelloWorld extends Component {
	state = {
		error: null,
		status: "",
		isLoaded: false
	}


	componentDidMount() {
		getStatus()
			.then(resp => {
				if (resp.status === 200) {
					console.log("Hello world :)")
					console.log(resp)
					return resp.json()
				}
				else {
					console.log("Servidor no respondió")
					return resp.json()
				}
			})
			.then(
				(result) => {
					console.log(result)
					this.setState({
						isLoaded: true,
						status: result.status
					});
				},
				(error) => {
					console.log(error)
					this.setState({
						isLoaded: true,
						error,
						status: "offline"
					});
				}
			)
	}




	render() {
		const { error, isLoaded, status } = this.state
		if (!isLoaded) {
			return <div>Loading...</div>;
		} else {
			return (
				<>
					<h1> Server Status </ h1>
					<p></p>
					<p className={"text-" + (error ? "danger" : "success")}>
						{status}
					</p>
				</>
			);
		}
	}
}
