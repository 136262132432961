import React, { Component } from 'react';
import yaml from 'yaml';
import styled from 'styled-components';
import correctSound from '../../assets/sounds/correct.mp3'; // Path to your correct sound file

const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa;
`;

const ChoiceGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

const ChoiceButton = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid #007bff;
  margin: 5px 0;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${({ isSelected, disabled }) => (disabled ? '#dcdcdc' : isSelected ? '#007bff' : '#fff')};
  color: ${({ isSelected, disabled }) => (disabled ? '#a0a0a0' : isSelected ? '#fff' : '#007bff')};
  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#dcdcdc' : '#007bff')};
    color: ${({ disabled }) => (disabled ? '#a0a0a0' : '#fff')};
  }
`;

const QuestionText = styled.span`
  margin-left: 10px;
  flex-grow: 1;
`;

const FeedbackMessage = styled.p`
  margin-top: 20px;
  font-size: 18px;
  color: ${({ isCorrect }) => (isCorrect ? 'green' : 'red')};
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
`;

const DefaultTitle = "Escoge la opcion correcta";
const DefaultGameTime = 10;
const DefaultMaxClicks = 10;

class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuestions: [],
      selectedAnswer: null,
      feedback: '',
      disabledOptions: [],
      score: 0,
      asserts: 0,
      questionStartTime: null,
      gameTime: this.props.gameTime || DefaultGameTime,
      remainingClicks: this.props.maxClicks || DefaultMaxClicks,
      showModal: false,
    };
    this.correctSound = new Audio(correctSound);
    this.timer = null;
  }

  componentDidMount() {
    this.generateQuestions(this.props.treeData);
    this.startTimer();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  startTimer = () => {
    this.timer = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.gameTime <= 1 || prevState.remainingClicks <=1) {
          clearInterval(this.timer);
          this.setState({ showModal: true });
          console.log("Final Score:", this.state.score);
        }
        return { gameTime: prevState.gameTime - 1 };
      });
    }, 1000);
  };

  generateQuestions = (tree) => {
    const allChildren = [];
    tree.forEach((node) => {
      node.children.forEach((child) => {
        allChildren.push({ value: node.value, child });
      });
    });

    const selectedQuestions = [];
    while (selectedQuestions.length < 1) {
      const randomChild = allChildren[Math.floor(Math.random() * allChildren.length)];
      if (!selectedQuestions.some((q) => q.child === randomChild.child)) {
        selectedQuestions.push(randomChild);
      }
    }

    const questions = selectedQuestions.map((question) => {
      let options = [question.value];
      while (options.length < 3) {
        const randomOption = tree[Math.floor(Math.random() * tree.length)].value;
        if (!options.includes(randomOption)) {
          options.push(randomOption);
        }
      }
      return {
        question: question.child,
        correctAnswer: question.value,
        options: options.sort(() => 0.5 - Math.random()),
      };
    });

    this.setState({ 
      currentQuestions: questions, 
      selectedAnswer: null, 
      feedback: '', 
      disabledOptions: [], 
      questionStartTime: Date.now() 
    });
  };

  handleAnswer = (question, answer) => {
    const { questionStartTime, disabledOptions } = this.state;
    const timeElapsed = (Date.now() - questionStartTime) / 1000;
    const failedClicks = disabledOptions.length
    if (answer === question.correctAnswer) {
      const questionScore = 1000 / ((1+timeElapsed) * Math.pow(10, disabledOptions.length));
      this.setState((prevState) => ({
        score: prevState.score + questionScore,
        remainingClicks: prevState.remainingClicks - 1,
        asserts: prevState.asserts + 1,
        selectedAnswer: null,
        feedback: 'Correct!',
      }));
      this.correctSound.play();
      setTimeout(() => {
        this.generateQuestions(this.props.treeData);
      }, 100);
    } else {
      this.setState((prevState) => ({
        // selectedAnswer: answer,
        remainingClicks: prevState.remainingClicks - 1,
        // score: prevState.score/2,
        disabledOptions: [...prevState.disabledOptions, answer],
      }));
    }
  };

  restartGame = () => {
    this.setState({
      currentQuestions: [],
      selectedAnswer: null,
      feedback: '',
      disabledOptions: [],
      score: 0,
      asserts: 0,
      remainingClicks: this.props.maxClicks || DefaultMaxClicks,
      gameTime: this.props.gameTime || DefaultGameTime,
      questionStartTime: null,
      showModal: false,
    });
    this.generateQuestions(this.props.treeData);
    this.startTimer();
  };

  render() {
    const { currentQuestions, selectedAnswer, feedback, disabledOptions, score, gameTime, showModal, asserts } = this.state;

    return (
      <GameContainer>
        <h3> { this.props.title || DefaultTitle }</h3>
        <br/>
        <br/>
        {/* <p>Time remaining: {gameTime}s</p> */}
        {currentQuestions.map((q, index) => (
          <div key={index}>
            <h1>{q.question}</h1>
            <ChoiceGroup role="radiogroup" aria-label="choice">
              {q.options.map((option, optIndex) => (
                <ChoiceButton
                  key={optIndex}
                  role="radio"
                  aria-checked={selectedAnswer === option}
                  onClick={() => this.handleAnswer(q, option)}
                  isSelected={selectedAnswer === option}
                  disabled={disabledOptions.includes(option)}
                  tabIndex={selectedAnswer === option ? 0 : -1}
                >
                  <span>{option}</span>
                </ChoiceButton>
              ))}
            </ChoiceGroup>
          </div>
        ))}
        <p>Score: {Math.round(score)}</p>
        {feedback && <FeedbackMessage isCorrect={feedback === 'Correct!'}>{feedback}</FeedbackMessage>}
        {showModal && (
          <Modal>
            <ModalContent>
              <h2>Game Over!</h2>
              <p>Asserts: {asserts}</p>
              <p>Final Score: {Math.round(score)}</p>
              <button onClick={this.restartGame}>Restart Game</button>
            </ModalContent>
          </Modal>
        )}
      </GameContainer>
    );
  }
}

export default Game;
