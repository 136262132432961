import * as constants from './constants'

const initialState = {
    state: {
        authenticated: false,
        user: null
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case constants.AUTH_SET_STATE:
            return {
                user: action.payload.user ?? null,
                authenticated: action.payload.authenticated
            }

        default:
            return state
    }

}
