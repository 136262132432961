export function stringify_values(input) {
	const json = JSON.stringify(input);
	const withStrings = JSON.parse(json, (key, val) => (
		typeof val !== 'object' && val !== null ? String(val) : val
	));
	return removeUnderscoresInKeys(withStrings)
}

function removeUnderscoresInKeys(jsonObject) {

	const keys = Object.keys(jsonObject);
	//console.log(jsonObject)

	keys.forEach((key) => {
		var newKey = key.replace('_', ' ');

		if (newKey !== key) {
			jsonObject[newKey] = jsonObject[key];
			delete jsonObject[key];
		}
	});

	return jsonObject;

}
