import React, { Component } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import HelloWorld from './components/HelloWorld';
import { About } from './components/About';
import Login from "./components/Login";

import RequireAuth from "./components/RequireAuth";
import Unauthorized from "./components/Unauthorized";
import RegisterUsers from "./components/RegisterUsers";
import Games from "./components/Games";
import GameDetail from "./components/GameDetail"

export default class RouterURL extends Component {
  render() {
    return (
      <Routes>
        <Route exact path="/status" element={<HelloWorld />} />
        <Route path="/auth/unauthorized" element={<Unauthorized />} />
        <Route exact path="/auth/login" element={<Login />} />
        <Route exact path="/auth/register" element={ <RequireAuth accessLevel={0}> <RegisterUsers /> </RequireAuth>} />
        <Route exact path="/games/:id" element={ <RequireAuth accessLevel={2}> <GameDetail /> </RequireAuth>} />
        <Route exact path="/games" element={ <RequireAuth accessLevel={1}> <Games /> </RequireAuth>} />
        <Route path="*" element={<RequireAuth accessLevel={2}>  <Navigate replace to="/auth/login" /> </RequireAuth>}  />
      </Routes>
    );
  }
}
