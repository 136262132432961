import React, { Component } from 'react'
import {  useParams } from 'react-router-dom';
import { deleteGame, getAllGames, getGameById } from '../../services/games.service.js'
import { withRouter } from '../../utils/navigation.utils'
import { connect } from "react-redux"
import TextQueryTree from "./TextQueryTree"

const saltRounds = 12;
const bcrypt = require('bcryptjs');

class GameDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            game: {},
        };
    }

    componentDidMount() {
        this.updateGames()
    }

    updateGames = () => {
        console.log("props", this.props);
        const id = this.props.params.id;
        console.log(id);
        const response = getGameById(id);
        response.then((resp) => {
            if (resp.status >= 400) throw resp
            else {
                return resp.json()
            }
        }).then((resp) => this.setState({ game: resp })).catch(error => {
            console.error("There was an error getting the game: ", error)
        })
    }

    deleteGameSubmit = (id) => {
        const gameResponse = window.confirm('Seguro que desea eliminar el usuario?')
        if (gameResponse) {
            const response = deleteGame(id)
            response.then((res) => res.json()).then(res => {
                console.log(res)
                this.updateGames()
            })
        }
    }

    render() {
        console.log("state", this.state)
        return (
            <div className="row mt-2" >
                {/* <div className="col-md-4 mx-2">
                    <div className="row form-group mb-3">
                        <p>Game</p>
                    </div>
                </div>

                <div className="col-md-6 mx-2">
                    <p>{this.state.game.specs?.name}</p>
                </div> */}
                { this.state.game.specs?.type === "tree" && 
                    <TextQueryTree
                        treeData = {this.state.game.specs?.tree}
                        title={this.state.game.specs?.title}
                        gameTime={this.state.game.specs?.gameTime}
                        maxClicks={this.state.game.specs?.maxClicks}
                    />
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state?.game?.specs
    };
}

export default withRouter(connect(mapStateToProps)(GameDetail))
