
export function getCookie(name) {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}

export async function checkLogin() {
	const response = fetch(`/api/auth/status`, {
		method: 'GET',
		credentials: 'same-origin',
		headers: {
			'X-CSRF-TOKEN': getCookie('csrf_access_token')
		}
	})
	return response
}

export async function getUserInfo() {
	const check_response = checkLogin()
	const auth_data = check_response.then(resp => {
		// console.log("Resp:", resp.text())
		if (resp.status === 200 || resp.status === 201) {
			console.log( {resp} )
			return resp.json()
		}
		else {
			throw resp
		}
	})
		.then(autenticated_state => {
			// console.log("Login status:", data)

			const response = fetch(`/api/auth/login`, {
				method: 'GET',
				credentials: 'same-origin',
				headers: {
					'X-CSRF-TOKEN': getCookie('csrf_access_token')
				}
			}).then(resp => {
				console.log({loginResp: resp})
				return resp.json()
			}).then(resp => {
				console.log("Auth body:", resp)

				return Object.assign({ user: resp }, autenticated_state)
			}
				, error => {
					console.log("Json error?: ", error)
				})
			return response
		},
			failure => {
				throw failure
			})
		.catch(err => {
			console.log("User unauthenticated: ", {err})
			return err
		})
	return auth_data
}

export async function loginUser(payload) {
	const response = fetch(`/api/auth/login`, {
		method: 'POST',
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	})
	return response
}

export async function logout() {
	// console.log("gonna delete cookie")
	await fetch(`/api/auth/login`, {
		method: 'DELETE'
	})
}

export function mapAuthStateToObject(state) {
	const authenticated = state.authState.authenticated;
	const user = state.authState.user ?? null;

	return {
		authenticated,
		user
	};
}