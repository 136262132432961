import React, { Component } from 'react'

class Unauthorized extends Component {

    render() {
        return (
            <>
                <div className="alert alert-warning mt-3 row" role="alert">
                    Usted no cuenta con el nivel de acceso suficientemente alto para accesar ese recurso.
                </div>
            </>
        );
    }
}


export default Unauthorized