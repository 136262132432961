import { getCookie } from './auth.service.js'


export async function createUser(payload) {
    const response = fetch(`/api/auth/users`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token')
        },
        body: JSON.stringify(payload)
    })
    return response
}


export async function updateUser(id, payload) {
    const response = fetch(`/api/auth/users/${id}`, {
        method: 'PUT',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token')
        },
        body: JSON.stringify(payload)
    })
    return response
}

export async function deleteUser(id) {
    const response = fetch(`/api/auth/users/${id}`, {
        method: 'DELETE',
        credentials: 'same-origin',
        headers: {
            'X-CSRF-TOKEN': getCookie('csrf_access_token')
        }
    })
    return response
}

export async function getAllUsers() {
    const response = fetch(`/api/auth/users`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'X-CSRF-TOKEN': getCookie('csrf_access_token')
        }
    })
    return response
}

export async function getUserById(id) {
    const response = fetch(`/api/auth/users/${id}`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'X-CSRF-TOKEN': getCookie('csrf_access_token')
        }
    })
    return response
}