import React, { Component } from 'react'
import { connect } from "react-redux"
import { Navigate } from "react-router-dom";

import store from '../../store'
import { setAuthState } from "../../store/auth/actions"
import { getUserInfo, mapAuthStateToObject } from '../../services/auth.service.js'
import { withRouter } from '../../utils/navigation.utils'

class RequireAuth extends Component {

    render() {
        const is_auth = this.props.authenticated
        const access = this.props.user?.access
        console.log({ is_auth, access })
        console.log({ props: this.props })

        if (is_auth) {

            if (this.props.accessLevel === undefined || access > this.props.accessLevel) {
                return <Navigate to="/auth/unauthorized" replace />;
            }
            else {
                return this.props.children
            }
        }
        else {
            return <Navigate to="/auth/login" replace />;
        }
    }
}


export default withRouter(connect(mapAuthStateToObject, { setAuthState })(RequireAuth))
