import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { Navbar } from './components/Navbar';
import RouterURL from './RouterURL';
import store from './store';
import { setAuthState } from './store/auth/actions';
import { getUserInfo } from './services/auth.service.js';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedAuth: null,
    };
  }

  componentDidMount() {
    getUserInfo().then((data) => {
      console.log('userInfo', data);
      store.dispatch(setAuthState(data));
      this.setState({ loadedAuth: data.authenticated });
    });
  }

  render() {
    const { loadedAuth } = this.state;

    if (loadedAuth === null) {
      return (
        <Router>
          <div>
            <p>Cargando...</p>
          </div>
        </Router>
      );
    }

    return (
      <Router>
        <div>
          <Navbar />
          <div className='row'>
            <div className='col-8 container justify-content-center'>
              <RouterURL />
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  authenticated: state.authState.authenticated !== null,
});

export default connect(mapStateToProps)(App);
