import React, { Component } from 'react'
import { connect } from "react-redux"
import { Link } from "react-router-dom";

import { mapAuthStateToObject } from '../../services/auth.service.js'
import { setAuthState } from "../../store/auth/actions"
import { withRouter } from '../../utils/navigation.utils'

class AuthLink extends Component {
    render() {

        // setAuthState y navigate están aquí solo para ignorarlos, porque sino es un error pasarlo al Link
        const { className, accessLevel, authenticated, user, setAuthState, navigate, ...rest } = this.props

        // console.log("REST:", rest)
        const disabled = (authenticated ? accessLevel !== undefined && (user.access > accessLevel) : true)

        return (
            <>
                <Link {...rest} className={className + (disabled ? " disabled" : "")}>
                    {this.props.children}
                </Link>

            </>
        )
    }
}

export default withRouter(connect(mapAuthStateToObject, { setAuthState })(AuthLink))
