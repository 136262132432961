import React, { Component } from "react";

class RegisterForm extends Component {


    render() {
        const cu_op = this.props.current_user ? this.props.current_user.access === 2 : false
        const cu_sup = this.props.current_user ? this.props.current_user.access === 1 : false
        const cu_admin = this.props.current_user ? this.props.current_user.access === 0 : false

        const cu_name = this.props.current_user ? this.props.current_user.name : ''
        const cu_username = this.props.current_user ? this.props.current_user.username : ''

        return (
            <form id="registerForm" onSubmit={this.props.onSubmit} className="card card-body">
                <div className="form-group mb-2">
                    <input type="text"
                        disabled={this.props.resetting}
                        className="form-control"
                        placeholder="Nombre"
                        defaultValue={cu_name}
                        name="name"
                        required
                        autoFocus
                    />
                </div>
                <div className="form-group mb-2">
                    <input type="Username"
                        disabled={this.props.resetting}
                        className="form-control"
                        placeholder="username"
                        defaultValue={cu_username}
                        name="username"
                        required
                    />
                </div>
                <div className="form-group mb-2">
                    <select className="form-select"
                        form="registerForm"
                        disabled={this.props.resetting}
                        name="access"
                        required>
                        <option value=''>Nivel de acceso</option>
                        {this.props.access <= 2 ? <option value={2} selected={cu_op}>Operador</option> : ''}
                        {this.props.access <= 1 ? <option value={1} selected={cu_sup}>Supervisor</option> : ''}
                        {this.props.access <= 0 ? <option value={0} selected={cu_admin}>Administrador</option> : ''}
                    </select>
                </div>
                <div className="form-group mb-2">
                    <input type="text"
                        className="form-control"
                        disabled={this.props.editing}
                        placeholder={this.props.resetting ? 'Nueva Contraseña' : 'Contraseña'}
                        name="password"
                        required={!this.props.editing}
                    />
                </div>
                <button className="btn btn-primary btn-block" type="submit">
                    {this.props.editing ? "Editar" : this.props.resetting ? 'Resetear' : 'Crear'}
                </button>
                {(this.props.editing || this.props.resetting) ?
                    <button className="btn btn-danger btn-block mt-1" type="reset"
                        onClick={this.props.cancelForm}>
                        Cancelar
                    </button>
                    : ''}
            </form>
        )
    }
}

export default RegisterForm