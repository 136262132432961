import { getCookie } from './auth.service.js'


export async function createGame(payload) {
    const response = fetch(`/api/games`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token')
        },
        body: JSON.stringify(payload)
    })
    return response
}


export async function updateGame(id, payload) {
    const response = fetch(`/api/games/${id}`, {
        method: 'PUT',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token')
        },
        body: JSON.stringify(payload)
    })
    return response
}

export async function deleteGame(id) {
    const response = fetch(`/api/games/${id}`, {
        method: 'DELETE',
        credentials: 'same-origin',
        headers: {
            'X-CSRF-TOKEN': getCookie('csrf_access_token')
        }
    })
    return response
}

export async function getAllGames() {
    const response = fetch(`/api/games`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'X-CSRF-TOKEN': getCookie('csrf_access_token')
        }
    })
    return response
}

export async function getGameById(id) {
    const response = fetch(`/api/games/${id}`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'X-CSRF-TOKEN': getCookie('csrf_access_token')
        }
    })
    return response
}

