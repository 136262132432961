import React, { Component } from 'react'

import { deleteGame, getAllGames } from '../../services/games.service.js'

const saltRounds = 12;
const bcrypt = require('bcryptjs');

class Games extends Component {

    constructor(props) {
        super(props);
        this.state = {
            games: [],
        };
    }


    componentDidMount() {
        this.updateGames()
    }

    updateGames = () => {
        console.log("props", this.props);
        const response = getAllGames()
        response.then((resp) => {
            if (resp.status >= 400) throw resp
            else {
                return resp.json()
            }
        }).then((resp) => this.setState({ games: resp })).catch(error => {
            console.error("There was an error getting the game: ", error)
        })
    }

    deleteGameSubmit = (id) => {
        const gameResponse = window.confirm('Seguro que desea eliminar el usuario?')
        if (gameResponse) {
            const response = deleteGame(id)
            response.then((res) => res.json()).then(res => {
                console.log(res)
                this.updateGames()
            })
        }
    }

    render() {
        return (
            <div className="row mt-2" >
                <div className="col-md-4 mx-2">
                    <div className="row form-group mb-3">
                        <p>Games</p>
                    </div>
                </div>

                <div className="col-md-6 mx-2">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Id</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.games.map((game) => (
                                <tr key={game.id}>
                                    <td>{game.specs?.name}</td>
                                    <td>{game.id}</td>
                                    <td>{game.specs?.type}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

        )
    }
}

export default Games