import React, { Component } from 'react'
import ConditionalLink from "./ConditionalLink.js";

class Button extends Component {

    render() {
        return (
            <>
                <ConditionalLink to={this.props.route} disabled={this.props.disabled} >
                    <button
                        type={this.props.type ?? "submit"}
                        onClick={this.props.onClick}
                        disabled={this.props.disabled}
                        style={{ width: "100%" }}
                        className={"btn btn-primary btn-block btn-" + (this.props.danger ? "danger" : "success")}
                    >{this.props.text}
                    </button>
                </ConditionalLink>
            </>
        );
    }
}


export default Button
