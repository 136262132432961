import React from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux"
import AuthLink from './AuthLink';
import { logout } from '../../services/auth.service.js'


export const Navbar = () => {
    const authenticated = useSelector((state) => state.authState.authenticated)
    const user = useSelector((state) => state.authState.user)

    const handle_logout = () => {
        logout().then(() => { window.location.reload() })
    }

    return <nav className={"navbar navbar-expand-lg navbar-expand-md navbar-expand-sm navbar-primary bg-light"}>
        <div className="container-fluid">
            <Link className={"navbar-brand link-"} to="/games">Smet Schools - English Version</Link>
            <div className="navbar" >
                <ul className="navbar-nav">
                    { user?.access === 0 && <li className="nav-item">
                        <AuthLink className="nav-link link-secondary" to="/auth/register" accessLevel={2}>usuarios</AuthLink>
                    </li>
                    }
                    { user?.access <= 1 && <li className="nav-item">
                        <AuthLink className="nav-link link-secondary" to="/games" accessLevel={1}>Juegos</AuthLink>
                    </li>
                    }
                    { authenticated ? 
                        <li className="nav-item">
                            <Link className="nav-link link-secondary" to="#" onClick={() => handle_logout()}> logout </Link>
                        </li>
                        :
                        <li className="nav-item">
                            <Link className="nav-link link-secondary" to="/auth/login"> login </Link>
                        </li>
                        
                    }
                    
                </ul>
            </div>
        </div>
    </nav>
};
