import React, { Component } from 'react'
import { connect } from "react-redux"

import { withRouter } from '../../utils/navigation.utils'
import { createUser, deleteUser, getAllUsers, getUserById, updateUser } from '../../services/register.service.js'
import { setAuthState } from "../../store/auth/actions"
import { mapAuthStateToObject } from '../../services/auth.service';
import RegisterForm from './RegisterForm'

const saltRounds = 12;
const bcrypt = require('bcryptjs');

class RegisterUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            resetting: false,
            current_user: null, // usuario que se está editando, etc...
            error_msg: null,

            users: [],
        };
    }

    componentDidMount() {
        this.updateUsers()
    }

    updateUsers = () => {
        const response = getAllUsers()
        response.then((resp) => {
            if (resp.status >= 400) throw resp
            else {
                return resp.json()
            }
        }).then((resp) => this.setState({ users: resp })).catch(error => {
            console.error("There was an error getting the user: ", error)
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();

        var response = null

        if (this.state.editing) {
            const user_info = {
                name: event.target.name.value,
                username: event.target.username.value,
                access: event.target.access.value,
            }
            console.log("New user info: ", JSON.stringify(user_info))
            response = updateUser(this.state.current_user.id, user_info)
        }
        else {
            const salt = bcrypt.genSaltSync(saltRounds);
            const hash = bcrypt.hashSync(event.target.password.value, salt);

            if (this.state.resetting) {
                const user_info = {
                    password: hash,
                }
                console.log("New password: ", JSON.stringify(user_info))
                response = updateUser(this.state.current_user.id, user_info)
            }
            else { // registering new user
                const user_info = {
                    name: event.target.name.value,
                    username: event.target.username.value,
                    access: event.target.access.value,
                    password: hash,
                }
                console.log("Registering user: ", JSON.stringify(user_info))
                response = createUser(user_info)
            }
        }

        response.then(resp => {
            if (resp.status >= 400) throw resp
            else {
                return resp.json()
            }
        })
            .then(data => {
                window.location.reload()

                console.log("User created/updated:")
                console.log(data)
            })
            .catch(error => {
                console.error("There was an error with the user update/creation", error)
            })
    }

    deleteUserSubmit = (id) => {
        const userResponse = window.confirm('Seguro que desea eliminar el usuario?')
        if (userResponse) {
            const response = deleteUser(id)
            response.then((res) => res.json()).then(res => {
                console.log(res)
                this.updateUsers()
            })
        }
    }

    activateResetting = (id) => {
        getUserById(id).then((resp) => {
            if (resp.status >= 400) throw resp
            else {
                return resp.json()
            }
        }).then((user) => {
            this.setState({ editing: false, resetting: true, current_user: user })
            // TODO add values in form
        }).catch(error => {
            console.error("There was an error getting the user: ", error)
        })
    }

    activateEditing = (id) => {
        getUserById(id).then((resp) => {
            if (resp.status >= 400) throw resp
            else {
                return resp.json()
            }
        }).then((user) => {
            this.setState({ resetting: false, editing: true, current_user: user })
            // TODO add values in form
        }).catch(error => {
            console.error("There was an error getting the user: ", error)
        })
    }

    cancelForm = (event) => {
        this.setState({ resetting: false, editing: false, current_user: null })
        document.getElementById("registerForm").reset();
    }

    render() {
        return (
            <div className="row mt-2" >
                <div className="col-md-4 mx-2">
                    <div className="row form-group mb-3">
                        <RegisterForm
                            onSubmit={this.handleSubmit}
                            resetting={this.state.resetting}
                            editing={this.state.editing}
                            access={this.props.user.access}
                            current_user={this.state.current_user}
                            cancelForm={() => this.cancelForm()}
                        />
                    </div>
                </div>

                <div className="col-md-6 mx-2">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Username</th>
                                <th>Access</th>
                                <th>Password Hash</th>
                                <th>Operations</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.users.map((user) => (
                                <tr key={user.id}>
                                    <td>{user.name}</td>
                                    <td>{user.username}</td>
                                    <td>{user.access}</td>
                                    <td>{user.password}</td>
                                    <td>
                                        {this.props.user.access <= user.access
                                            ?
                                            <div className="container">

                                                <div className="row">
                                                    <button type="button"
                                                        className="col btn btn-secondary "
                                                        onClick={(e) => this.activateEditing(user.id)}
                                                    >
                                                        Editar
                                                    </button>
                                                    <button type="button"
                                                        className="col btn btn-warning "
                                                        onClick={(e) => this.activateResetting(user.id)}
                                                    >
                                                        Resetear Contraseña
                                                    </button>
                                                </div>
                                                <div className="row">
                                                    <button type="button"
                                                        className="btn btn-danger"
                                                        onClick={(e) => this.deleteUserSubmit(user.id)}
                                                    >
                                                        Eliminar
                                                    </button>
                                                </div>

                                            </div>
                                            :
                                            ''
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

        )
    }
}

export default withRouter(connect(mapAuthStateToObject, { setAuthState })(RegisterUsers))