import React, { Component } from 'react'
import { Link } from "react-router-dom";

class ConditionalLink extends Component {
	render() {
		return (
			<>
				{this.props.to && !this.props.disabled ? (
					<Link style={{ width: "100%" }} to={this.props.to}>
						{this.props.children}
					</Link>) : (this.props.children)
				}
			</>
		)
	}
}

export default ConditionalLink
